.wedding-table-plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}


.rectangleTable {
  width: 95vw;
  height: 5vh;
  background-color: #ffffff;
  border: solid 1px black;
  border-radius: 28px;
  margin-top: 30px;
  margin-left: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle at center, rgb(255, 255, 240) 70%, rgb(47, 74, 40) 100%);
}

.circle-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: visible;
  margin-top: 12px;
  margin-top: 50px;
}