.table {
    height: 156px;
    width: 156px;
    border-radius: 78px;
    background: radial-gradient(circle at center, rgb(255, 255, 240) 40%, rgb(47, 74, 40) 100%);
    border: 1px solid black;
}


.tableContainer {
    width: max-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    
}

