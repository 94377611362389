.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
  
  .container {
    position: relative;
    text-align: center;
  }
  .PageTitleContainer {
    justify-content: center;
    font-size: 25px;
    color: rgb(0, 0, 0);
    text-align: center;
    background-color: ivory;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: -40px;
  }
  .countDownContainer {
    display: flex;
    justify-content:space-around;
    flex-direction: row;
    background-color: #edfbe8b3;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background: radial-gradient(circle at center, rgb(255, 255, 240) 70%, rgb(47, 74, 40) 100%);
    padding: 12px;
    margin-top: 90px;
    font-size: 16px;
  }