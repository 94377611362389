.image-gallery {
 display:flex;
 justify-content: space-between;
 flex-direction: column;
 align-items: center;
 margin: 10px;
}

.image-gallery img {
  border:solid 2px;
  border-bottom-color:#ffe;
  border-left-color:#eed;
  border-right-color:#eed;
  border-top-color:#ccb;
  max-height:100%;
  max-width:100%;
  background-color: #c3cbdc;
  background-image: linear-gradient(147deg, #dcd7c3 0%, #f4f1ed 74%);
  border:solid 5vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.568) inset, 0 5px 10px 5px rgba(0, 0, 0, 0.701);
  box-sizing:border-box;
  display:inline-block;
  margin:10vh 10vw;
  height:34vh;
  padding:2vmin;
  position:relative;
  text-align:center;
  &:before {
    border-radius:2px;
    bottom:-2vmin;
    box-shadow:0 2px 5px 0 rgba(0,0,0,.25) inset;
    content:"";
    left:-2vmin;
    position:absolute;
    right:-2vmin;
    top:-2vmin;
  }
  &:after {
    border-radius:2px;
    bottom:-2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
    content:"";
    left:-2.5vmin;
    position:absolute;
    right:-2.5vmin;
    top:-2.5vmin;
  }
}

.image-caption {
  border:solid 2px;
  border-bottom-color:#ffe;
  border-left-color:#eed;
  border-right-color:#eed;
  border-top-color:#ccb;
  max-height:100%;
  max-width:100%;
  background-color: #c3cbdc;
  background-image: linear-gradient(147deg, #dcd0c3 0%, #f4f2ed 74%);
  border:solid 2vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:8px;
  border-right-color:#eee;
  border-top-color:#dddddd;
  box-shadow:0 0 5px 0 rgba(34, 48, 20, 0.846) inset, 0 5px 10px 5px rgba(0, 0, 0, 0.607);
  box-sizing:border-box;
  height:8vh;
  width: 40vw;
  padding:1vmin;
  position:relative;
  text-align:center;
  font-size: 16px;
  margin-top: -76px;
  margin-left: 120px;
  margin-right: 120px;
}

