.text_box {
  background: radial-gradient(circle at center, rgb(255, 255, 240) 70%, rgba(47, 74, 40, 0.77) 100%);
  border: 2px solid #3f3f3f;
  border-radius: 15px;
  padding: 20px;
  width: 70%;
  margin: 50px auto;
  font-size: larger;
  text-align: center;
}

.text_box h1 {
  text-align: center;
}

.text_box p {
  text-align: center;
}

.bold_text {
  font-size: 30px;
}


