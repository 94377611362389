.navContainer {
    width: calc(100% - 44px);
    padding: 20px;
    display:flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    font-size: 20px;
    position: fixed;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    border: 2px solid #5d5858;
    box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1000;
}


.link {
    text-decoration: none;
    color: #000000;
    padding: 8px 16px 8px 16px;
    transition: all ease-out .3s;
    border-radius: 10px;
}

.link:hover {
   color: #32532cc3;
    
}

.titleText {
    color:#000000;
    font-size: 24px !important;
}

.sideMenu {
    margin-top: 82px;
    height: 100vh;
    width: 200px;
    display: flex;
    position: fixed;
    top: 0;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 20px;
    padding-top: 4px;
    transition: all ease-in-out 0.2s;
    z-index: 1000;
    background: radial-gradient(circle at center, rgb(255, 255, 240) 70%, rgb(47, 74, 40) 100%);
    border: 2px solid #5d5858;
    box-shadow: 0 16px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}