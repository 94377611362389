.ImgText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: visible;
    position: relative;
    transition: all 0.3s;
    background-color: rgb(255, 255, 255);
    object-fit: cover;
}

.textBox {
    overflow: visible; 
    position: absolute; 
    font-size: 8px;
    border: 0.5px solid black;
    border-radius: 4px;
    padding: 2px;
    background-color: rgba(123, 151, 109, 0.783);
    margin-top: 4px;
}