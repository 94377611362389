.event {
  width: 70%; 
  background-color: #ccc;
  margin: 48px auto;
  padding: 15px; 
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  background: radial-gradient(circle at center, rgb(255, 255, 240) 70%, rgba(47, 74, 40, 0.77) 100%);
  font-size: 20px;
  border: solid grey 1px;
  box-shadow: 8px 4px rgb(58, 70, 60);
  border-radius: 8px;
}

.event .bold_text {
  font-weight: bold;
}

.event p {
  text-align: center;
}


  