.gallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: max-content;
}


.frame {
    margin: 16px;
    border-radius: 8px;
    
  }
  
.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
}

#imageUpload {
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 90;
    position: absolute;
}

#photoImg {
    /* position: absolute;
    left: 0; */
    height: 76px;
}

.buttonDiv {
    height: 100px;
    width: 100px;
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 89;
}